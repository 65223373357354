import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
const DfCardSpecialComponent = () => Utils.externalComponent2("df-card-special");

@Component({
  components: {
    DfCardSpecial: DfCardSpecialComponent,
  },
})
export default class DfSectionSpecialComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: String, default: "" })
  position!: string;
  @Prop({ type: Object, default: null })
  promotion: DfPromotion;

  get specials(): Array<DfContent> {
    return this.$store.getters.promotionContents.filter((content: DfContent) => content.type.code === "SPC") || [];
  }

  get specialsByPosition(): Array<DfContent> {
    const specials: Array<DfContent> = this.specials.filter((special: DfContent) => {
      const value: any = Utils.getPropertyValue(special, Utils.PROPERTY_POSITION, "LIST", {});
      return value.key === this.position;
    });

    // TODO: Eliminare a inizio 2023
    const specialsFake: Array<DfContent> = this.specials.filter((special: DfContent) => {
      const value: string = Utils.getPropertyValue(special, Utils.PROPERTY_POSITION, "STRING");
      return value === this.position;
    });

    return specials.length ? specials : specialsFake;
  }

  @Watch("specialsByPosition")
  private changeVisibility() {
    this.$emit("setVisibility", this.specialsByPosition.length > 0);
  }
}
